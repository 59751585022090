import React, { Component } from 'react';
import {
  Navbar,
  // NavbarToggler,
  // Collapse,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { FaLinkedin } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  // componentDidUpdate() {
  //   let hash = this.props.location.hash.replace('#', '');
  //   if (hash) {
  //       let node = ReactDOM.findDOMNode(this.refs[hash]);
  //       if (node) {
  //           node.scrollIntoView();
  //       }
  //   }
  // }
  
  render() {
    return (
      <div className="App">
        <Navbar color="dark" light expand="md">
          <NavbarBrand href="/">JS</NavbarBrand>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="mailto:john@johnsolomonlaw.com">Contact</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/bio">About</NavLink>
              </NavItem>
            </Nav>
        </Navbar>
        <header className="App-header">
          <h1 style={{ fontSize: '50px' }}>
            The Law Office Of <br/> John Solomon
          </h1>
          <div className='info'>
            <div className='contact'>
              Contact <br/>
                <div className='contact-details'>
                  <a
                    className="App-link"
                    href="mailto:john@johnsolomonlaw.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaEnvelope className='icon'size={40} /> john@johnsolomonlaw.com
                  </a>
                  <br/>
                  <a
                    className="App-link"
                    href="tel:+19785785512"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaPhone className='icon' size={40} /> +1 (978) 578-5512
                  </a>
                </div>
                <br/>
              </div>
              <div className='contact' ref='bio'>
                About <br/>
                <div className='contact-details'>
                  <a
                    className="App-link"
                    href="https://www.linkedin.com/in/john-solomon-87ab2634/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className='icon' size={40} /> LinkedIn
                  </a>
                </div>
              </div>
            </div>
        </header>
      </div>
    );
  }
}

export default App;
